<template>
  <!-- 订单管理 -->
  <div>
    <Modal
      :title="title"
      :value="value"
      @input="(val) => $emit('input', val)"
      :width="800"
      @on-visible-change="modalShow"
    >
      <Form :label-width="100" ref="form" :model="form" :rules="rules">
        <Row>
          <Col span="12">
            <FormItem label="订单名称" prop="name">
              <Input
                :disabled="defaultEdeit"
                clearable
                placeholder="请输入订单名称"
                v-model="form.name"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="订单类型" prop="orderType">
              <Select
                :disabled="defaultEdeit"
                v-model="form.orderType"
                style="width: 260px"
                placeholder="请选择所属部门"
                clearable
              >
                <Option v-for="el in orderList" :key="el.id" :value="el.id">{{
                  el.name
                }}</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="发货时间" prop="deliveryTime">
              <DatePicker
                type="date"
                :disabled="defaultEdeit"
                placeholder="请选择发货时间"
                v-model="form.deliveryTime"
                style="width: 260px"
              ></DatePicker>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="收货时间" prop="receiverTime">
              <DatePicker
                type="date"
                :disabled="defaultEdeit"
                placeholder="请选择收货时间"
                v-model="form.receiverTime"
                style="width: 260px"
              ></DatePicker>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="备注" prop="remark">
              <Input
                clearable
                type="textarea"
                :disabled="defaultEdeit"
                v-model="form.remark"
                :rows="5"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="添加产品"> </FormItem>
          </Col>
          <Col span="11" style="display: flex; justify-content: flex-end">
            <Button
              title="添加"
              type="primary"
              style="margin-left: 10px"
              @click="addConditionGroup"
            >
              <Icon type="md-add" />
              <span>添加产品信息</span>
            </Button>
          </Col>
        </Row>
        <div
          class="conditionGroup"
          v-for="(item, index) in itemList"
          :key="index"
        >
          <div class="flexboxa" style="margin-bottom: 20px; padding-left: 44px">
            <Row>
              <Col span="10">
                <FormItem label="产品编号" required>
                  <Input
                    clearable
                    :disabled="defaultEdeit"
                    v-model="item.productId"
                    style="width: 200px"
                  ></Input>
                </FormItem>
              </Col>
              <Col span="10">
                <FormItem label="产品名称" required>
                  <Input
                    clearable
                    :disabled="defaultEdeit"
                    v-model="item.productName"
                    style="width: 200px"
                  ></Input>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span="10">
                <FormItem label="产品数量" required>
                  <Input
                    clearable
                    :disabled="defaultEdeit"
                    v-model="item.productNum"
                    style="width: 200px"
                  ></Input>
                </FormItem>
              </Col>
              <Col span="10">
                <FormItem label="产品单位" required>
                  <Input
                    clearable
                    :disabled="defaultEdeit"
                    v-model="item.productUnit"
                    style="width: 200px"
                  ></Input>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span="10">
                <FormItem label="产品图片">
                  <upload-image ref="upload" v-model="item.picUrl" :max="1" />
                </FormItem>
              </Col>
              <Col span="10">
                <FormItem label="产品备注">
                  <Input
                    clearable
                    type="textarea"
                    :disabled="defaultEdeit"
                    v-model="item.remark"
                    :rows="2"
                    style="width: 200px"
                  ></Input>
                </FormItem>
              </Col>
            </Row>
          </div>
          <!-- <span class="remove" title="移除" @click="() => removeCG(index)"
            ><Icon type="md-close"
          /></span> -->
        </div>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => $emit('input', false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    RowId: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    DefluteValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
    defaultEdeit: Boolean,
  },
  data() {
    return {
      config: {
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      orderList: [
        { id: "1", name: "转运订单" },
        { id: "2", name: "施用订单" },
        // { id: "3", name: "自填订单" },
        // { id: "4", name: "其他" },
      ],
      itemList: [],
      actionGroup: [],
      deptList: [],
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      carDetail: {
        show: false,
        info: {},
      },
      form: {
        orderFarmId:"",
        userId: "",
        name: "", //姓名
        deliveryTime: "", //发货时间
        receiverTime: "", //收货时间
        companyNo: "", //公司编号
        remark: "", //备注
        orderType: "", //订单类型
        orderNo:"",//订单编号
        picUrl:"",
        id:""
      },
      rules: {
        name: [{ required: true, message: "请输入姓名" }],
        deliveryTime: [
          { required: true, type: "date", message: "请选择发货时间" },
        ],
        receiverTime: [
          { required: true, type: "date", message: "请选择收货时间" },
        ],
        orderType: [{ required: true, message: "请选择订单类型" }],
      },
    };
  },
  methods: {
    //提交数据
    submit() {
      if (this.itemList.length == "0") {
        this.$Message.error("请填写订单详情");
        return;
      }
      
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = this.form;
        let url;
        if (params.id) {
          url = this.$api.ORDER_INFO.UPDATE;
        } else {
          url = this.$api.ORDER_INFO.ADD;
        }
        params.deliveryTime = moment(this.form.deliveryTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        params.receiverTime = moment(this.form.receiverTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        let imagelist =[];
        imagelist = JSON.parse(JSON.stringify(this.itemList))
        if(this.itemList.length > 0){
            for (let index = 0; index < imagelist; index++) {
              this.itemList[index].picUrl = imagelist[index].picUrl.replace(/http:\/\/img.xd.sidwit.com\//g,"");
            }
        }
        // params.picUrl = this.form.picUrl.replace(
        //   /http:\/\/img.xd.sidwit.com\//g,
        //   ""
        // );
        this.$post(url, { itemList: this.itemList, ...params })
          .then(() => {
            this.$Message.success(params.userId ? "编辑成功！" : "添加成功！");
            this.DriverModel = false;
            this.$router.push("orderInfo")
            this.$emit("input", false);
            this.$emit("refrestList");
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
    //循环添加订单商品
    addConditionGroup() {
      this.itemList.push({
        productId: "",
        productName: "",
        productNum: "",
        productUnit: "",
        picUrl: "",
        remark: "",
      });
    },
    //获取部门列表
    getDeptList() {
      this.$post(this.$api.company.BARNCHTREE)
        .then((res) => {
          this.deptList = res;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        userId: "",
        name: "",
        companyNo: "",
        noValidPeriod: "",
        orderFarmId:""
      };
      this.$refs.form.resetFields();
    },
    removeCG(index) {
      this.itemList = this.itemList.filter((el) => el.index !== index);
    },
  },
  mounted() {
    this.getDeptList();
  },
  watch: {
    value(val) {
      if (val) {
        if (this.DefluteValue) {
          //执行初始化model操作
          let formData ={};
          formData = JSON.parse(JSON.stringify(this.DefluteValue))
          this.form.orderNo= formData.id.toString();
          // this.form.id= formData.id.toString();
          this.form.orderFarmId = formData.publisherFarmId.toString();
        }
      } else {
        //   执行重置数据操作
      }
    },
  },
};
</script>

<style lang="less" scoped>
.conditionGroup {
  border: 1px dashed #00464f;
  padding: 10px 0 10px 0;
  margin-top: 20px;
  .remove {
    color: #e4393c;
    font-size: 24px;
    cursor: pointer;
    justify-content: flex-end;
    display: flex;
    width: 100%;
    padding-right: 20px;
  }
}
</style>